import React, { useEffect, useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const MaximoScale = ({ data = null }) => {
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const [transitionDirection, setTransitionDirection] = useState("down");
    const [backgroundColor, setBackgroundColor] = useState(null);
    const [isMobile, setIsMobile] = useState(false);

    const bgColorsServices = {
        "Implementation & Integration": "#B2BEB5",
        "Managed Support": "#ffe4e1",
        "MAS 9 Migration": "#fafad2",
        "Custom add-ons": "#e6e6fa",
        "EAM360 mobile app for Maximo": "#f0f8ff",
    };

    useEffect(() => {
        setTransitionDirection(activeItemIndex > 0 ? "down" : "down");
    }, [activeItemIndex]);

    useEffect(() => {
        if (data && data.length > 0) {
            setActiveItemIndex(0);
            const initialTitle = data[0]?.title;
            setBackgroundColor(bgColorsServices[initialTitle] || "#B2BEB5");
        }
    }, [data]);

    // for mobile view logic
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        handleResize();
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleItemClick = (index) => {
        setActiveItemIndex(index);
        const selectedTitle = data[index]?.title;
        setBackgroundColor(bgColorsServices[selectedTitle] || "#B2BEB5");
        console.log("color ", setBackgroundColor);
    };

    const activeItem = data ? data[activeItemIndex] : null;

    return (
        <div className="maximo-scale-grid">
            {/* List of buttons to click on */}
            <div className="maximo-scale-grid__list">
                {data?.map((item, index) => (
                    <div
                        key={item.id}
                        className={`list-item ${index === activeItemIndex ? "active" : ""}`}
                        onClick={() => handleItemClick(index)}
                    >
                        <div className="maximo-scale-grid__list-desc">
                            {item?.serviceIcon?.gatsbyImageData ? (
                                <GatsbyImage
                                    image={item.serviceIcon.gatsbyImageData}
                                    alt={item.title}
                                    className="maximo-solution-ogimage"
                                />
                            ) : (
                                <img
                                    className="maximo-solution-ogimage"
                                    src={item.serviceIcon.file.url || ""}
                                    alt={item.title}
                                />
                            )}
                            <h2 className="list-title">{item.title}</h2>
                        </div>
                        {index === activeItemIndex && (
                            <>
                                <p className="list-description">{item.description?.description}</p>
                                <div className="solution-features">
                                    {activeItem?.solutionLists?.map((feature, idx) => (
                                        <div
                                            key={`feature_${idx}`}
                                            className="solution-features-item text-p4 text-clr-primary text-fw-medium"
                                        >
                                            <ul>
                                                <li>{feature}</li>
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                                {/* render video for mobile view  */}
                                <div>
                                    {isMobile &&
                                        activeItem?.ogImage?.file?.contentType === "video/mp4" && (
                                            <video
                                                key={activeItem.ogImage.file.url}
                                                controls={true}
                                                autoPlay
                                                muted
                                                loop
                                                className="ibm-service-video"
                                                style={{ width: "100%", height: "auto" }}
                                            >
                                                <source
                                                    src={activeItem.ogImage.file.url}
                                                    type={activeItem.ogImage.file.contentType}
                                                />
                                                Your browser does not support the video tag.
                                            </video>
                                        )}
                                </div>
                            </>
                        )}
                    </div>
                ))}
            </div>

            {/* Content that changes based on the selected item */}
            {!isMobile && (
                <div
                    className={`content ${transitionDirection}`}
                    style={{ backgroundColor: backgroundColor }}
                >
                    {/* Display image */}
                    <div className="maximo-campaign-solution__image">
                        {!isMobile &&
                        activeItem?.ogImage?.file?.contentType === "video/mp4" &&
                        !activeItem?.ogImage?.gatsbyImageData ? (
                            <video
                                key={activeItem.ogImage.file.url} // Add key to force re-render
                                controls={false}
                                autoPlay
                                muted
                                loop
                                className="ibm-service-video"
                                style={{ width: "100%", height: "100%" }}
                            >
                                <source
                                    src={activeItem.ogImage.file.url}
                                    type={activeItem.ogImage.file.contentType}
                                />
                                Your browser does not support the video tag.
                            </video>
                        ) : (
                            <GatsbyImage
                                image={activeItem?.ogImage.gatsbyImageData}
                                alt={activeItem.title}
                                className="maximo-solution-ogimage"
                                style={{ width: "auto" }}
                                loading="lazy"
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default MaximoScale;
